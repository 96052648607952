<template>
  <div class="modal-overlay" @click="$emit('close')">
    <div class="modal" @click.stop>
      <div class="modal__box">
        <div class="close" @click="$emit('close')">
          <img class="close-img" src="@/assets/images/icons/close.svg" alt="">
        </div>
      </div>
      <div class="main_wrapper">
        <p class="modal__text">{{ 'Вы уверены, что хотите удалить группу &laquo;' + title + '&raquo;?' }}</p>
      </div>
      <div class="button_wrapper">
        <button class="btn delete_button" @click="deleteGroup" :disabled="disableButton">Удалить</button>
        <button class="btn btn-accent" @click="$emit('close')">Отменить</button>
      </div>
    </div>
  </div>
</template>

<script>

import {useStore} from "vuex";
import {ref} from "@vue/runtime-core";
import {useRouter} from "vue-router";

export default {
  name: "DeleteModal",
  props: {
    title: String,
    id: [Number, String],
  },
  data() {
    let store = useStore();
    let router = useRouter();
    let disableButton = ref(false);
    return { store, router, disableButton };
  },
  methods: {
    deleteGroup() {
      if (this.id) {
        this.disableButton = true;
        this.store.dispatch('group/deleteGroup', this.id).then(() => {
          this.disableButton = false;
          this.$emit('deleted');
        });
      }
    }
  },
}
</script>

<style scoped lang="scss">

.modal-overlay {
  z-index: 5;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
  overflow: auto;
}

@media only screen and (max-height: 570px) {
  .modal {
    margin-top: 0 !important;
  }
}

.main_wrapper {
  margin: auto 0;
}

.modal {
  display: flex;
  flex-direction: column;
  overflow-y: initial !important;
  position: relative;
  background-color: white;
  height: 250px;
  width: 500px;
  margin-top: 10%;
  padding: 20px 40px;

  &__box {
    text-align: left;
  }

  &__text {
    padding-top: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    @media (max-width: 715px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__btn {
    padding-top: 30px;
  }
}

.close {
  position: absolute;
  right: 30px;
  cursor: pointer;
}

.close-img {
  width: 25px;
}

.button_wrapper {
  display: flex;
  justify-content: flex-end;
  column-gap: 15px;
  padding-top: 10px;
}

.btn-box-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.delete_button {
  color: #ffffff;
  background-color: #f45846;
}

.delete_button:hover {
  opacity: 0.9;
}

@media (max-width: 715px) {
  .modal {
    margin-top: 50%;
  }
}
</style>